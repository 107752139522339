import React from "react"
import GlobalStyle from "../components/layout/GlobalStyle"
import ResultHeader from "../components/resultComponents/ResultHeader"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import HeaderWithPhoto from "../components/layout/header/HeaderWithPhoto"
import { useProducts } from "../hooks/useProducts"
import { graphql } from "gatsby"
import { faPlus, faSortDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { device } from "../utils/mediaQueries"
import { routes } from "../utils/routeHeaders"
import PageButton from "../components/layout/buttons/PageButton"

const Header1 = styled.h1`
  color: #0481b1;
  font-size: 55px;
  line-height: 50px;
  font-weight: 800;
  @media ${device.tablet} {
    font-size: 35px;
  }
`

const Header2 = styled.h2`
  color: #0481b1;
  font-size: 40px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.96px;
  @media ${device.tablet} {
    font-size: 25px;
  }
`

const Header3 = styled.h3`
  color: black;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.96px;
  text-align: center;
  margin: 30px;
`

const Container = styled.div`
  margin: 50px 70px;
  @media ${device.tablet} {
    margin: 20px 30px;
  }
`

const Button = styled.div`
  padding: 10px 15px;
  color: white;
  background-color: orange;
  width: 200px;
  text-align: center;
  font-weight: 800;
  font-size: 23px;
  margin: 50px auto;
`

const IconContainer = styled.div`
  @media ${device.tablet} {
    margin: 0px;
  }
`
const IconItem = styled.div`
  display: flex;
  padding: 30px;
  justify-items: center;
  align-items: center;
  @media ${device.tablet} {
    margin: 0px;
    flex-direction: column;
    align-items: center;
  }
`

const Icon = styled(FontAwesomeIcon)`
  font-size: ${props => (props.white ? "40px" : "25px")};
  padding: ${props => (props.white ? "2px" : "10px 15px")};
  color: ${props => (props.white ? "white" : "orange")};
  margin-right: 20px;
  @media ${device.tablet} {
    margin: 0px;
  }
`

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 50px 70px;
  @media ${device.tablet} {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
    margin: 2px;
    grid-template-columns: 1fr;
  }
`

const LeftPart = styled.div``
const RightPart = styled.div``

const BenefitsBox = styled.div`
  padding: 30px;
  box-shadow: 3px 5px 8px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  margin: 2rem;
  @media ${device.tablet} {
    margin: 0px 0px;
    margin-top: 20px;
  }
`

const Desc = styled.div`
  background-color: white;
  padding: 30px;
  box-shadow: 3px 5px 8px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  line-height: 27px;
  margin: 2rem;
  @media ${device.tablet} {
    margin: 0px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px;
`
const HyperLink = styled.a`
  text-decoration: none;
`

const Template = ({ data }) => {
  const productsHook = useProducts(data.allProductsJson.edges, true)
  const imageData = data.file.childImageSharp.fluid
  const allProducts = productsHook.filteredProducts

  const benefits = data.markdownRemark.frontmatter.benefits.map((b, i) => {
    return (
      <IconContainer key={i}>
        <IconItem>
          <Icon icon={faPlus} /> <div>{b}</div>
        </IconItem>
      </IconContainer>
    )
  })

  const buttons = data.markdownRemark.frontmatter.links.map((l, i) => {
    return <PageButton key={i} text={routes[l]} url={l} />
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.metaTitle}</title>
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.metaDesc}
        />
        <html lang="fi" />
      </Helmet>

      <GlobalStyle>
        <HeaderWithPhoto
          image={imageData}
          alt={data.markdownRemark.frontmatter.innerFilter}
        >
          <HeaderContainer>
            <LeftPart>
              <Desc>
                <Header1>{data.markdownRemark.frontmatter.title}</Header1>
                {data.markdownRemark.frontmatter.desc}
                <HyperLink href="#results">
                  <Button>
                    Valitse suosikkisi
                    <Icon icon={faSortDown} white />
                  </Button>
                </HyperLink>
              </Desc>
            </LeftPart>
            <RightPart>
              <BenefitsBox>
                <Header2>Miksi valita tämä?</Header2>
                <div>{benefits}</div>
              </BenefitsBox>
            </RightPart>
          </HeaderContainer>
        </HeaderWithPhoto>
        <a name="results"></a>
        <ResultHeader
          products={allProducts}
          location={data.markdownRemark.frontmatter.path}
        />
        <Header3>Sinua saattaa kiinnostaa myös nämä:</Header3>
        <ButtonContainer>{buttons}</ButtonContainer>
        <Container
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></Container>
      </GlobalStyle>
    </>
  )
}

export const query = graphql`
  query($filter: String!, $img: String!, $path: String!) {
    allProductsJson(
      filter: { innerType: { eq: $filter } }
      sort: { fields: price, order: ASC }
    ) {
      edges {
        node {
          agency
          auto
          benefits
          city
          country
          departures
          desc
          id
          innerType
          length
          slug
          link
          name
          price
          return
          ship
          transport
          type
          shipImg
          visitTime
        }
      }
    }
    file(base: { eq: $img }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        img
        metaTitle
        innerFilter
        metaDesc
        desc
        benefits
        links
      }
    }
  }
`

export default Template
